/**
 * https://github.com/insites/cookieconsent/
 *
 * @export
 * @class CookieConsentService
 */
export class CookieConsentService {
  private static CDN_SRC = 'https://cdn.jsdelivr.net/npm/cookieconsent@3/build/cookieconsent.min.js';

  /**
   * Append the cookieconsent script in body and define behavior after load.
   *
   * @memberof CookieConsentService
   */
  public execute() {
    const script = document.createElement('script');

    script.src = CookieConsentService.CDN_SRC;
    script.crossOrigin = 'anonymous';
    script.async = true;
    script.defer = true;
    script.onload = () => this.onLoad.call(this);

    document.body.appendChild(script);
  }

  /**
   * Behavior after script fully loaded.
   *
   * @private
   * @param {string} id
   * @memberof CookieConsentService
   */
  private onLoad() {
    if (cookieconsent && cookieconsent.hasInitialised) {

      cookieconsent.initialise({
        content: {
          dismiss: 'Entendi',
          href: '/paginas/politica-de-cookies/',
          link: 'Política de Cookies.',
          message: 'Este website utiliza cookies para lhe dar a melhor experiência possível. ' +
          'Ao continuar a navegação está a aceitar a sua utilização.<br>Para saber mais, consulte a nossa ',
        },
        cookie: {
          secure: true,
        },
        law: {
          countryCode: 'PT',
          regionalLaw: false,
        },
        onPopupClose: () => {
          const element = document.querySelector('.cc-window');
          if (element) {
            element.classList.remove('cc-opened');
          }
        },
        onPopupOpen: () => {
          const element = document.querySelector('.cc-window');
          if (element) {
            setTimeout(() => element.classList.add('cc-opened'), 24);
          }
        },
      });
    } else {
      console.warn('CookeyConsent not loaded as expected.');
    }
  }
}
