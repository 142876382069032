import * as Sentry from '@sentry/browser';

import { settings } from '@app/core';

/**
 * https://docs.sentry.io/error-reporting/quickstart/?platform=browser
 *
 * @export
 * @class SentryService
 */
export class SentryService {

  /**
   * Instantiate Sentry with settings from Django.
   *
   * @static
   * @memberof SentryService
   */
  public static init() {
    if (settings.get('SENTRY_ACTIVE')) {
      Sentry.init(settings.get('SENTRY_CONFIGS'));
    }
  }
}
