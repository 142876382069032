import { settings } from '@app/core';

export class RecaptchaService {
  /**
   * Id of element containing the recaptcha key.
   *
   * @private
   * @type {string}
   * @memberof RecaptchaService
   */
  private id: string;

  /**
   * Creates an instance of RecaptchaService.
   *
   * @param {string} [id='id_recaptcha']
   * @memberof RecaptchaService
   */
  public constructor(id: string = 'id_recaptcha') {
    this.id = id;
  }

  /**
   * Append the recaptcha script in body and define behavior after load.
   *
   * @memberof RecaptchaService
   */
  public execute() {
    if (settings.get('RECAPTCHA_ACTIVE')) {
      const script = document.createElement('script');

      script.src = settings.get('RECAPTCHA_CLIENT_URL');
      script.async = true;
      script.defer = true;
      script.onload = () => this.onLoad.call(this, this.id);

      document.body.appendChild(script);
    } else {
      console.warn('Recaptcha is disabled. If this is not intended, please check RECAPTCHA_KEY or RECAPTCHA_ACTIVE configurations.');
    }
  }

  /**
   * Behavior after recaptcha load.
   *
   * @private
   * @param {string} id
   * @memberof RecaptchaService
   */
  private onLoad(id: string) {
    const recaptchaInput = document.getElementById(id);

    if (recaptchaInput) {
      grecaptcha.ready(() => {
        grecaptcha.execute(settings.get('RECAPTCHA_KEY'), {}).then((token: any) => {
          recaptchaInput.setAttribute('value', token);
        });
      });
    }
  }
}
