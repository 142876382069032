import IMask from 'imask';

export class IMaskService {

  private selector: string;

  public constructor(selector: string = '[data-mask]') {
    this.selector = selector;
  }

  /**
   * Apply IMask plugin.
   *
   * @memberof DjangoMessagesService
   */
  public apply() {
    const elements = document.querySelectorAll(this.selector);

    if (elements) {
      elements.forEach((element: Element) => {
        const inputMask = element.getAttribute('data-mask');
        let options = {mask: inputMask};
        if (element.getAttribute('data-mask-type') === 'date') {
          options = Object.assign(options, {
            blocks: {
              DD: {
                from: 1,
                mask: IMask.MaskedRange,
                to: 31,
              },
              MM: {
                from: 1,
                mask: IMask.MaskedRange,
                to: 12,
              },
              YYYY: {
                from: 1900,
                mask: IMask.MaskedRange,
                to: new Date().getFullYear(),
              },
            },
          });
        }
        IMask(element, options);
      });
    }
  }
}
