import FontFaceObserver from 'fontfaceobserver';

import { Router } from '@app/core';
import { routes } from './routings';

export class App {

  /**
   * Bootstrap application.
   *
   * @static
   * @returns
   * @memberof App
   */
  public static bootstrap() {
    return new App();
  }

  /**
   * Instance of routes declared on routings.ts file.
   * @memberof App
   */
  private router = new Router(routes);

  constructor() {
    this.router.check();
    this.checkFontLoaded();
  }

  private checkFontLoaded() {
    const openSans400 = new FontFaceObserver('Open Sans', {weight: 400});
    const openSans600 = new FontFaceObserver('Open Sans', {weight: 600});
    const openSans700 = new FontFaceObserver('Open Sans', {weight: 700});

    Promise.all([
      openSans400.load(),
      openSans600.load(),
      openSans700.load(),
    ]).then(() => {
      document.documentElement.className += ' fonts-loaded';
    });
  }
}
