export interface IRoute {
  /**
   * Regular expression to be matched. If not provided, view is always called.
   * @type {RegExp}
   */
  match?: RegExp;

  /**
   * Callable to be executed.
   */
  view: () => void;
}

export declare type Routes = IRoute[];

export class Router {
  /**
   * Defined routes;
   * @private
   * @type {IRoute[]}
   */
  private routes: Routes;

  public constructor(routes: Routes) {
    this.routes = routes;
  }

  /**
   * Start router checking, calling always undefined matches and matching body class views.
   *
   * @memberof Router
   */
  public check() {
    // Fire common init
    this.callMatchUndefined();

    // Fire page-specific init JS, and then finalize JS
    document.body.className
      .toLowerCase()
      .split(/\s+/)
      .forEach((className: string) => this.callMatchName(className.replace(/^view\-/, '')));
  }

  /**
   * Call view that match with name.
   *
   * @private
   * @param {string} name
   * @memberof Router
   */
  private callMatchName(name: string) {
    const route = this.routes.find((item: IRoute) => item.match !== undefined && item.match.test(name));

    if (route) {
      route.view();
    }
  }

  /**
   * Call views with undefined match.
   *
   * @private
   * @memberof Router
   */
  private callMatchUndefined() {
    this.routes.forEach((route: IRoute) => route.match === undefined && route.view());
  }
}
