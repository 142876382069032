import Noty from 'noty';

interface IMessage {
  type: Noty.Type;
  text: string;
}

export class DjangoMessagesService {

  private id: string;

  public constructor(id: string = 'djangoMessages') {
    this.id = id;
  }

  /**
   * Search for messages defined by django and show them.
   *
   * @memberof DjangoMessagesService
   */
  public showMessages() {
    const element = document.getElementById(this.id);

    if (element) {
      element.querySelectorAll('li').forEach((elem: HTMLLIElement) => {
        this.showMessage({
          text: elem.innerHTML,
          type: elem.className as Noty.Type,
        });
      });
    }
  }

  /**
   * Show message using Noty.
   *
   * @private
   * @param {IMessage} message
   * @memberof DjangoMessagesService
   */
  public showMessage(message: IMessage) {
    new Noty({
      progressBar: true,
      text: message.text,
      theme: 'bootstrap-v4',
      timeout: 10000,
      type: message.type,
    }).show();
  }
}
