import { Routes } from '@app/core';
import { CommonView, SignupView } from '@app/views';

/**
 * @description
 *
 * Represents router configuration.
 *
 * `Routes` is an array of route configurations. Each one has the following properties:
 *
 * - `match` define regex matching rule. If not defined, will always execute the view.
 * - `view` is the view to run.
 *
 * Example:
 * ```
 * [
 *   { view: CommonView },
 *   { match: /homepage/, view: HomepageView },
 *   { match: /accounts_*./, view: AccountEditView },
 * ]
 * ```
 */
export const routes: Routes = [
  { view: CommonView },
  { match: /account_signup/, view: SignupView },
];
