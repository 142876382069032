import './polyfills';

import '@styles/main.scss';

import 'bootstrap/js/dist/collapse';

import { App } from '@app/app';
import { SentryService } from '@app/services';

// start sentry earliest possible
SentryService.init();

document.addEventListener('DOMContentLoaded', () => {
  App.bootstrap();
});
