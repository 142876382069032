import * as Ladda from 'ladda';

export class LaddaService {

  private selector: string;

  public constructor(selector: string = '[type="submit"]:not(.btn-anchor)') {
    this.selector = selector;
  }

  /**
   * Apply Ladda plugin.
   *
   * @memberof LaddaService
   */
  public bind() {
    Ladda.bind(this.selector);
  }
}
